@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Poppins Light'), local('Poppins-Light'),
    url('/Poppins/Poppins-Light.woff2') format('woff2');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('Poppins Medium'), local('Poppins-Medium'),
    url('/Poppins/Poppins-Medium.woff2') format('woff2');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Poppins-Bold'), local('Poppins-Bold'),
    url('/Poppins/Poppins-Bold.woff2') format('woff2');
}
